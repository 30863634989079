import { Component, OnInit, Input } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Textos } from '../../../shared/data/textos';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo


  public today: number = Date.now();

  email: string = '';
  loading: boolean = false;
  message: string = '';
  newsletter: boolean = true; // Assuming this controls the visibility
  textos: Textos[];
  
  TITOL_NEWSLETTER: string;
  TXT_NEWSLETTER: string;
  NEWSLETTER_ESCRIBA_SU_MAIL: string;
  NEWSLETTER_BOTON: string;
  NEWSLETTER_ERROR: string;
  NEWSLETTER_SUCCESS: string;
  NEWSLETTER_ERROR_SUBSCRIPCION: string;
  NEWSLETTER_MAILVALIDO: string;
  NEWSLETTER_BOTON_ENVIANDO: string;
  NEWSLETTER_GRACIAS:string;
  OTROS_ENLACES:string;
  PRIVACITAT:string;
  COOKIES:string;
  LEGAL:string;

  idioma: string;

  constructor(private router: Router, public navServices: NavService,private http: HttpClient) { 

    this.idioma="1";
  }

  ngOnInit(): void {
console.log("dondeestamos","footer-one");
    if(localStorage.getItem("idioma")){
      this.idioma=localStorage.getItem("idioma")!;
    }
    else{  
      this.idioma='1';
     
    }
    console.log("dondeestamos","footer-one","idioma",this.idioma);

    this.navServices.GetTextos().subscribe(items => {
      console.log("dondeestamos","llegantextos",items);
      this.textos=items;
    

      
      let t1 = this.textos.find(texto => texto.identificador === 'TITOL_NEWSLETTER');
      this.TITOL_NEWSLETTER = t1 ? t1.texte : '-'; 


      t1 = this.textos.find(texto => texto.identificador === 'TXT_NEWSLETTER');
      this.TXT_NEWSLETTER = t1 ? t1.texte : '-'; 
       
      
      t1 = this.textos.find(texto => texto.identificador === 'NEWSLETTER_ESCRIBA_SU_MAIL');
      this.NEWSLETTER_ESCRIBA_SU_MAIL = t1 ? t1.texte : '-'; 

      t1 = this.textos.find(texto => texto.identificador === 'NEWSLETTER_BOTON');
      this.NEWSLETTER_BOTON = t1 ? t1.texte : '-'; 

      t1 = this.textos.find(texto => texto.identificador === 'NEWSLETTER_BOTON_ENVIANDO');
      this.NEWSLETTER_BOTON_ENVIANDO = t1 ? t1.texte : '-'; 
       
      t1 = this.textos.find(texto => texto.identificador === 'NEWSLETTER_MAILVALIDO');
      this.NEWSLETTER_MAILVALIDO = t1 ? t1.texte : '-'; 

      t1 = this.textos.find(texto => texto.identificador === 'NEWSLETTER_ERROR_SUBSCRIPCION');
      this.NEWSLETTER_ERROR_SUBSCRIPCION = t1 ? t1.texte : '-'; 

      t1 = this.textos.find(texto => texto.identificador === 'NEWSLETTER_ERROR');
      this.NEWSLETTER_ERROR = t1 ? t1.texte : '-'; 
      
      

      t1 = this.textos.find(texto => texto.identificador === 'NEWSLETTER_SUCCESS');
      this.NEWSLETTER_SUCCESS = t1 ? t1.texte : '-'; 

      t1 = this.textos.find(texto => texto.identificador === 'NEWSLETTER_GRACIAS');
      this.NEWSLETTER_GRACIAS = t1 ? t1.texte : '-'; 

      
      t1 = this.textos.find(texto => texto.identificador === 'OTROS_ENLACES');
      this.OTROS_ENLACES = t1 ? t1.texte : '-'; 
      t1 = this.textos.find(texto => texto.identificador === 'PRIVACITAT');
      this.PRIVACITAT = t1 ? t1.texte : '-'; 
      t1 = this.textos.find(texto => texto.identificador === 'COOKIES');
      this.COOKIES = t1 ? t1.texte : '-'; 
      t1 = this.textos.find(texto => texto.identificador === 'LEGAL');
      this.LEGAL = t1 ? t1.texte : '-'; 
    });


  }

  navigateTo(path: string) {
   
    window.location.href = path;
   
  }
  

  subscribe(event: Event) {

 

    event.preventDefault();
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

   
    // Check if the email is empty or invalid
    if (!this.email || !emailPattern.test(this.email)) {
      Swal.fire({
        title: 'Error',
        text: this.NEWSLETTER_MAILVALIDO,
        icon: 'success',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#a99134' 
      });
      return;
    }

    this.loading = true;
    this.http.post<any>('https://oliart.es/api/subscribirse.php', { email: this.email, idioma: this.idioma }).subscribe({
      next: (response) => {
        this.loading=false;
        console.log(response);
        if (response.success) {
          Swal.fire({
            title: this.NEWSLETTER_SUCCESS,
            text: this.NEWSLETTER_GRACIAS,
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#a99134' 
          });
          this.loading=false;
        } else {
          Swal.fire({
            title:'Error!',
            text: this.NEWSLETTER_ERROR_SUBSCRIPCION,
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#a99134' 
          });
          this.loading=false;
        }
      },
      error: () => {
        Swal.fire({
          title:'Error!',
          text: this.NEWSLETTER_ERROR,
          icon: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#a99134' 
        });
        this.loading=false;

      },
      complete: () => {
        this.loading = false;
        this.email = '';
      },
    });
  }

}
